import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';
const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        "lead",
        () => import('./lead/lead.module').then(m => m.LeadModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "customer",
        () => import('./customer/customer.module').then(m => m.CustomerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "supplier",
        () => import('./supplier/supplier.module').then(m => m.SupplierModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "company",
        () => import('./company/company.module').then(m => m.CompanyModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "subjectsettings",
        () => import('./subject-settings/subject-settings.module').then(m => m.SubjectSettingsModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "customerstaff",
        () => import('./customer-staff/customer-staff.module').then(m => m.CustomerStaffModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "supplierstaff",
        () => import('./supplier-staff/supplier-staff.module').then(m => m.SupplierStaffModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "leadstaff",
        () => import('./lead-staff/lead-staff.module').then(m => m.LeadStaffModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "companystaff",
        () => import('./company-staff/company-staff.module').then(m => m.CompanyStaffModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "customerdestination",
        () => import('./customer-destination/customer-destination.module').then(m => m.CustomerDestinationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "supplierdestination",
        () => import('./supplier-destination/supplier-destination.module').then(m => m.SupplierDestinationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "leaddestination",
        () => import('./lead-destination/lead-destination.module').then(m => m.LeadDestinationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "agent",
        () => import('./agent/agent.module').then(m => m.AgentModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "riskscoredashboardlongop",
        () => import('./risk-score-dashboard-long-op/risk-score-dashboard-long-op.module').then(m => m.RiskScoreDashboardLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "generalsubjectriskscore",
        () => import('./general-subject-risk-score/general-subject-risk-score.module').then(m => m.GeneralSubjectRiskScoreModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "anticipayonboardinglongop",
        () => import('./anticipay-on-boarding-long-op/anticipay-on-boarding-long-op.module').then(m => m.AnticipayOnBoardingLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "anticipayservicesdatalongop",
        () => import('./anticipay-services-data-long-op/anticipay-services-data-long-op.module').then(m => m.AnticipayServicesDataLongOpModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
